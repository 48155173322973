* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  margin: 0;
  height: -webkit-fill-available;
  height: 100vh;
  max-height: 100vh;
  background-color: rgb(192, 194, 196);
  scroll-behavior: smooth;
}

body {
  font-size: 13px;
  font-family: Helvetica Neue, Helvetica, Segoe UI, Arial, sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  justify-content: space-between;
}

/* div {
  scrollbar-width: 0px;
  -ms-overflow-style: none;
}

div::-webkit-scrollbar {
  display: none;
} */

/* move close button of drawer to right*/
.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}

.suspense-fallback {
  flex-grow: 1;
  flex: 1;
  position: relative;
}

.header-nav {
  height: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  background-color: #333;
}

.header-nav ul {
  height: 99%;
  align-self: center;
  display: flex;
  flex-flow: row;
  list-style: none;
  margin: 0px;
}

.header-nav ul li:first-child {
  border-right: 2px solid #595959;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
}

.header-nav ul > li {
  color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
}

.site-logo {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.site-logo-img {
  max-width: 130px;
  max-height: 60px;
  object-fit: contain;
}

.dropdown:hover,
.header-nav ul li:not(:first-child):hover {
  background: linear-gradient(180deg, #209cff 0, #1e69a6);
  cursor: pointer;
}

.active-header-nav {
  background-image: linear-gradient(180deg, #209cff 0, #1e69a6);
  font-weight: 600;
}

.header-nav .dropdown-menu {
  position: absolute;
  left: 0px;
  top: calc(98%);
  width: calc(100% + 5rem);
  color: #333;
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  display: flex;
  flex-flow: column;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  z-index: 9999999;
}

.header-nav .dropdown:hover > .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.header-nav .dropdown .dropdown-menu .dropdown-link {
  padding: 0.5rem;
  color: #333;
  text-decoration: none;
}

.header-nav .dropdown .dropdown-menu .dropdown-link:hover {
  background-color: #f5f5f5;
}

.header-nav .active-menu {
  background-color: #f5f5f5;
  font-weight: 600 !important;
}

#root {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Segoe UI, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: -webkit-fill-available; */
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.show-scroll-bar {
  -ms-overflow-style: auto;
}

.login-canvas {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("assets/backg.jpg");
  height: 100%;
  display: flex;
}

.login-brand {
  flex: 1;
  display: flex;
  justify-content: center;
}

.login-brand-logo {
  max-width: 400px;
  height: auto;
  margin-bottom: 15px;
}

.login-panel {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.login-footer {
  width: 100%;
  padding: 1.25rem;
  position: absolute;
  bottom: 0px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.login-content {
  margin: 0px;
  max-width: 520px;
  height: auto;
  background-color: rgb(255, 255, 255);
  padding: 2.5rem;
  display: flex;
  flex-flow: column;
  border-radius: 0.375rem;
}

.login-form-button {
  width: 100%;
}

.over-view-content {
  flex: 1;
  background-color: rgb(249, 249, 249);
  overflow-x: hidden;
  overflow-y: auto;
}

.overview-inventory-tile {
  width: 100%;
  border: 1px solid rgb(231, 232, 238);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
  height: 130px;
  display: flex;
  flex-flow: column;
  padding: 10px;
  justify-content: space-between;
}

.overview-inventory-tile:hover {
  box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.overview-inventory-tile-title {
  color: #8c8c8c;
  font-weight: 700;
  text-transform: uppercase;
}

.overview-inventory-tile-body {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.overview-inventory-tile-body-left {
  height: 72px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.overview-inventory-tile-body-right {
  height: 72px;
  display: flex;
  flex-flow: column;
  justify-content: end;
}

.overview-inventory-tile-volume {
  color: #262626;
  font-weight: bolder;
  font-size: 1.7em;
}

.overview-chart {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

  gap: 20px;
}

.overview-chart-card {
  height: 350px;
}

.gauge-location-selected {
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  justify-content: start;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 20px;
}

.gauge-no-location-selected {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.gauge-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;
  padding: 10px;
}

.gauge-tank-table {
  table-layout: fixed;
  width: 100%;
}

.gauge-tank-table > tbody > tr > td {
  padding-right: 0.1rem;
  padding-left: 0.1rem;
}

.gauge-tank-table > tbody > tr:hover {
  background-color: #f5f5f5;
  text-decoration: underline;
}

.gauge-tile-title-link {
  color: black;
}

.gauge-tile-title-link:hover {
  color: blue;
  font-weight: bolder;
  text-decoration: underline;
}

.gauge-tank-detail-tr {
  border-bottom: 1px solid #f0f0f0;
  height: 30px;
}

.gauge-tank-detail-tr:hover {
  background-color: #f5f5f5;
}

.gauge-tank-detail-tr-value-td {
  text-align: right;
}

.gauge-warning-animation {
  font-size: 20px;
  color: red;
  font-weight: bolder;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

.gauge-delivery-animation {
  font-size: 20px;
  color: #209cff;
  font-weight: bolder;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

.gauge-warning-animation-stack {
  color: red;
  font-weight: bolder;
  font-size: 2rem;
  position: absolute;
  left: 60px;
  top: 90px;
  z-index: 10;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.transaction-content {
  flex: 1;
  background-color: rgb(249, 249, 249);
  display: flex;
  flex-direction: column;
  position: relative;
}

.transaction-card {
  flex: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid rgb(240, 240, 240);
  justify-content: space-between;
}

.transaction-card-title {
  flex: none;
}

.transaction-table-summary-cell {
  display: flex;
  flex-flow: row;
  overflow: scroll;
  justify-content: start;
  flex-wrap: wrap;
  gap: 5px;
}

.transaction-table-summary-cell::-webkit-scrollbar {
  display: none;
}

.customers-content {
  position: relative;
  flex: 1;
  background-color: rgb(249, 249, 249);
  display: flex;
  flex-direction: column;
}

.customers-card {
  flex: none;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid rgb(240, 240, 240);
  justify-content: space-between;
}

.customers-card-title {
  flex: none;
}

.totalizer-card {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid rgb(240, 240, 240);
  position: relative;
}

.totalizer-card-title {
  flex: none;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.table-wrap {
  margin: 0px 12px 0px;
  /* padding: 0px 10px 0px; */
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.admin-profile-table-wrap {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.admin-table-wrap {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.admin-product-table-wrap {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 5px;
}

.transaction-pagination {
  height: 48px;
  flex: none;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
}

.customer-pagination {
  height: 48px;
  flex: none;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
}

.admin-over-view-content {
  flex: 1;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  background-color: rgb(249, 249, 249);
  padding: 5px 10px 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.admin-profile-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgb(249, 249, 249);
  padding: 10px px 10px;
  overflow: hidden;
}

.admin-profile-navigation {
  width: 250px;
  padding: 5px 10px 0px;
  overflow: hidden;
}

.admin-profile-navigation ol {
  padding-left: 10px;
  margin-top: 8px;
}

.admin-profile-navigation li {
  list-style-type: none;
  margin-bottom: 10px;
}

.admin-profile-navigation li a:hover {
  text-decoration: underline;
}

.admin-profile-main {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.metre-overview {
  margin: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.transaction-overview {
  margin: 20px;
}

.transaction-management-content {
  margin: 10px 10px 10px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.transaction-detail-row:hover {
  background-color: #e6f4ff;
}

.card-nav {
  width: 250px;
  padding: 20px 20px 0px;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}

.card-nav-navigation {
  width: 250px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 30px;
  overflow: hidden;
}

.card-nav-navigation ol {
  padding-left: 10px;
  margin-top: 8px;
}

.card-nav-navigation li {
  list-style-position: inside;
  list-style-type: none;
  margin-bottom: 10px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-nav-navigation li a:hover {
  text-decoration: underline;
}

.card-pagination {
  height: 48px;
  flex: none;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
}

.totalizer-content-layout {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.totalizer-nav {
  width: 250px;
  padding: 20px 20px 0px;
  overflow: hidden;
  background-color: white;
}

.totalizer-main-content {
  flex: 1;
  /* display: flex;
  flex-flow: row;
  overflow-y: hidden;
  overflow-x: auto;
  gap: 10px;
  padding: 10px; */
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  justify-content: center;
}

.totalizer-main-content-no-metre {
  flex: 1;
  display: flex;
  flex-flow: row;
  overflow-y: hidden;
  overflow-x: auto;
  gap: 10px;
  padding: 10px;
  flex-grow: 1;
  justify-content: center;
}

.totalizer-main-content-empty {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.totalizer-card-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.5em;
  row-gap: 0.5em;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.totalizer-card-grid::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

.totalizer-tile-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 10px;
  justify-content: flex-start;
  padding: 10px;
  gap: 1px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
  background-color: white;
}

.totalizer-tile-item-list {
  overflow: hidden;
  margin-top: 5px;
}

.totalizer-row:hover {
  background-color: #fafafa;
}

.custom-logics-overview {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.custom-logics-card {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid rgb(240, 240, 240);
  position: relative;
}

.custom-logics-title {
  flex: none;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.custom-logics-content-layout {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.custom-logics-main-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 15px;
  padding: 15px;
}

.custom-logics-main-content-empty {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
}

.custom-logics-tile-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.custom-logics-tile-statistics {
  min-width: 200px;
  min-height: 100px;
  max-height: 100px;
  height: 150;
  border-radius: 5px;
  border: 1px solid rgb(240, 240, 240);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.custom-logics-no-metre-data {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
}

.card-main-content {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.card-table-toolbar {
  display: flex;
  background-color: rgb(226, 227, 227);
  padding: 5px;
  justify-content: space-between;
}

.card-content-wrap {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
}

.card-table-wrap {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .metre-briefing {
  flex: 1 0 calc(25%);
  width: 100%;
  border: 1px rgb(192, 194, 196) solid;
  margin: 10px;
  display: flex;
  flex-direction: column;
} */

.footer {
  text-align: center;
  height: 40px;
  background-color: rgb(53, 63, 60);
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-nav {
  background-color: rgb(53, 63, 60);
  height: 66px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-profile {
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
}

.admin-metre-toolbar {
  display: flex;
  background-color: rgb(226, 227, 227);
  padding: 5px;
  gap: 5px;
  justify-content: space-between;
}

.admin-transaction-toolbar {
  flex: none;
  background-color: rgb(226, 227, 227);
  padding: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
}

.admin-customer-toolbar {
  flex: none;
  background-color: rgb(226, 227, 227);
  padding: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
}

.admin-products-content-layout {
  flex: 1;
  display: flex;
  flex-flow: row;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-left: 7px;
  margin-right: 7px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.admin-products-content-layout::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

.admin-product-content-nav {
  width: 250px;
  padding: 5px 10px 0px;
  overflow: hidden;
  background-color: white;
}

.button-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.disabled-record {
  color: #bfbfbf;
}

.loader-over-lay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.loader-over-lay-relative {
  position: absolute;
  width: "100%";
  height: "100%";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.layout-404 {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.middle-404 {
  height: 356px;
  max-height: 356px;
  width: 100%;
}

.title-404 {
  width: 100%;
  height: 309px;
  max-height: 309px;
  display: flex;
  flex-flow: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
}

.footer-404 {
  width: 100%;
  height: 47px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

/* .ditto-grid {
  flex: 1;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  justify-content: center;
} */

.ditto-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;
}

.ditto-no-location-selected {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ditto-display {
  /* width: 300px; */
  height: 320px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;
  /* zoom: 93%; */
  background-color: white;
}

.ditto-display-gray {
  filter: grayscale(1);
}

.ditto-display:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s ease-in-out;
}

.ditto-display-title {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  text-overflow: ellipsis;
  font-weight: 500;
  border-bottom: 1px solid #d9d9d9;
}

.ditto-display-modal-body {
  flex: 1;
  zoom: 1.3;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: center;
}

.ditto-display-body {
  flex: 1;
  zoom: 82%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: center;
}

.bay-monitor-no-location-selected {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bay-monitor-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: min-content;
  gap: 10px 10px;
  padding: 10px;
}

.bay-tile {
  position: relative;
  border: 1px solid lightgray;
  border-radius: 5px;
  min-width: 300px;
  height: 190px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.bay-tile-header {
  border-bottom: 1px solid lightgray;
  background-color: #e6f4ff;
  height: 2.5em;
  font-size: 1em;
  font-weight: 500;
  line-height: 2.5em;
  padding-left: 10px;
  padding-right: 10px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.bay-tile-body {
  flex-grow: 1;
  height: 60px;
  display: flex;
  flex-flow: column;
  justify-content: start;
}

.bay-status-row {
  height: 50px;
  display: flex;
  flex-flow: row;
}

.bay-status-spacer {
  width: 10px;
  height: 50px;
}

.bay-status {
  border: 1px solid grey;
  flex-grow: 1;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 1.6em;
}

.bay-tile-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 10px;
}

.bay-tile-info-cell {
  height: 2em;
  font-size: 0.9em;
  line-height: 2em;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.bay-tile-footer {
  min-height: 30px;
  border-top: 1px solid lightgray;
  background-color: #f5f5f5;
  color: #8c8c8c;
  text-align: right;

  /* height: 2.5em; */
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8em;
  /* line-height: 2.5em; */
  padding-left: 5px;
  padding-right: 5px;
  /* text-overflow: ellipsis; */
  text-wrap: nowrap;
  flex-wrap: nowrap;
  display: flex;
  flex-flow: row;
  justify-content: start;
  align-items: center;

  gap: 3px;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bay-tile-footer::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 992px) {
  .login-canvas {
    flex-flow: column;
    justify-content: center;
  }

  .login-brand {
    display: none;
  }

  .login-brand-logo {
    display: none;
  }

  .login-panel {
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .login-content {
    min-width: 350px;
  }

  .login-footer {
    width: 100%;
    padding: 1.25rem;
    position: absolute;
    text-align: center;
    bottom: 0px;
    font-size: 0.75rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.ditto-keyboard-key {
  width: 40px;
  /* height: 25px; */
  /* border: 1px solid black;
  text-align: center;
  line-height: 25px;
  background-color: lightgray; */
}

/* .ditto-keyboard-key:hover {
  filter: brightness(150%);
  cursor: pointer;
} */

.ditto-keyboard-func-key {
  width: 60px;
  /* height: 25px; */
  /* border: 1px solid black;
  text-align: center;
  line-height: 25px;
  background-color: lightgray; */
}

/* .ditto-keyboard-func-key:hover {
  filter: brightness(150%);
  cursor: pointer;
} */

@media only screen and (max-width: 980px) {
  .layout {
    width: 980px;
    min-width: 980px;
    margin-inline: auto;
  }
  .metre-overview {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .off-rack-load-customer-dropdown {
    display: none;
  }
}

@media only screen and (min-width: 980px) {
  .layout {
    width: 980px;
    min-width: 980px;
    margin-inline: auto;
  }
  .metre-overview {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .off-rack-load-customer-dropdown {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .layout {
    width: 1200px;
    margin-inline: auto;
  }
  .metre-overview {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .off-rack-load-customer-dropdown {
    display: block;
    width: 200px;
  }
}

@media only screen and (min-width: 1475px) {
  .layout {
    width: 1475px;
    margin-inline: auto;
  }
  .metre-overview {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .off-rack-load-customer-dropdown {
    display: block;
    width: 250px;
  }
}
